"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const core_1 = require("@angular/core");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const dnaR_dropdown_multiSelect_component_1 = require("./dnaR-dropdown-multiSelect.component");
const dnaR_dropdown_multiSelect_list_filter_pipe_1 = require("./dnaR-dropdown-multiSelect-list-filter.pipe");
const dnaR_spinner_module_1 = require("../dnaR-spinner/dnaR-spinner.module");
const shared_module_1 = require("../../shared.module");
const i0 = require("@angular/core");
class DnaRDropdownMultiSelectModule {
    static forRoot() {
        return {
            ngModule: DnaRDropdownMultiSelectModule
        };
    }
}
exports.DnaRDropdownMultiSelectModule = DnaRDropdownMultiSelectModule;
DnaRDropdownMultiSelectModule.ɵmod = i0.ɵɵdefineNgModule({ type: DnaRDropdownMultiSelectModule });
DnaRDropdownMultiSelectModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DnaRDropdownMultiSelectModule_Factory(t) { return new (t || DnaRDropdownMultiSelectModule)(); }, imports: [[shared_module_1.SharedModule, common_1.CommonModule, forms_1.FormsModule, dnaR_spinner_module_1.DnaRSpinnerModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DnaRDropdownMultiSelectModule, { declarations: [dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect, dnaR_dropdown_multiSelect_list_filter_pipe_1.DnaRDropdownMultiSelectFilter], imports: [shared_module_1.SharedModule, common_1.CommonModule, forms_1.FormsModule, dnaR_spinner_module_1.DnaRSpinnerModule], exports: [dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DnaRDropdownMultiSelectModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [shared_module_1.SharedModule, common_1.CommonModule, forms_1.FormsModule, dnaR_spinner_module_1.DnaRSpinnerModule],
                declarations: [dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect, dnaR_dropdown_multiSelect_list_filter_pipe_1.DnaRDropdownMultiSelectFilter],
                exports: [dnaR_dropdown_multiSelect_component_1.DnaRDropdownMultiSelect],
            }]
    }], null, null); })();
