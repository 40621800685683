"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const angular = require("angular");
const angular_hybrid_1 = require("@uirouter/angular-hybrid");
const core_1 = require("@angular/core");
const shared_module_1 = require("../common/shared.module");
const platform_browser_1 = require("@angular/platform-browser");
const dnaR_datePicker_module_1 = require("../common/components/dnaR-datePicker/dnaR-datePicker.module");
const dialog_1 = require("@angular/material/dialog");
const organisation_service_1 = require("./services/organisation.service");
const organisation_states_1 = require("./organisation.states");
const accommodation_type_price_history_modal_component_1 = require("./accommodation-type-price-history-modal/components/accommodation-type-price-history-modal.component");
const dnaR_dropdown_multiSelect_module_1 = require("../common/components/dnaR-dropdown-multiSelect/dnaR-dropdown-multiSelect.module");
const dnaR_input_module_1 = require("../common/components/dnaR-input/dnaR-input.module");
const common_1 = require("@angular/common");
const forms_1 = require("@angular/forms");
const edit_bed_status_component_1 = require("./facility/edit-bed-status.component");
const static_1 = require("@angular/upgrade/static");
const devextreme_angular_1 = require("devextreme-angular");
const tooltip_1 = require("@angular/material/tooltip");
const dialog_2 = require("@angular/material/dialog");
const icon_1 = require("@angular/material/icon");
const facility_service_1 = require("./services/facility.service");
const facility_details_tab_group_component_1 = require("./facility/facility-details/components/facility-details-tab-group/facility-details-tab-group.component");
const facility_details_tab_component_1 = require("./facility/facility-details/components/facility-details-tab/facility-details-tab.component");
const facility_details_view_tab_component_1 = require("./facility/facility-details/components/facility-details-tab/facility-details-view-tab.component");
const facility_details_edit_tab_component_1 = require("./facility/facility-details/components/facility-details-tab/facility-details-edit-tab.component");
const lead_tags_tab_component_1 = require("./facility/facility-details/components/lead-tags-tab/lead-tags-tab.component");
const lead_tags_view_tab_component_1 = require("./facility/facility-details/components/lead-tags-tab/lead-tags-view-tab.component");
const lead_tags_edit_tab_component_1 = require("./facility/facility-details/components/lead-tags-tab/lead-tags-edit-tab.component");
const tabs_1 = require("@angular/material/tabs");
const chips_1 = require("@angular/material/chips");
const value_with_effective_date_component_1 = require("./facility/value-with-effective-date/value-with-effective-date.component");
const form_field_1 = require("@angular/material/form-field");
const button_1 = require("@angular/material/button");
const overlay_1 = require("@angular/cdk/overlay");
const dnaR_email_input_module_1 = require("../common/components/dnaR-email-input/dnaR-email-input.module");
const i0 = require("@angular/core");
const i1 = require("@uirouter/angular-hybrid");
// Downgrade and register items in AngularJs
angular.module('app')
    .directive('editBedStatus', static_1.downgradeComponent({ component: edit_bed_status_component_1.EditBedStatusComponent }))
    .directive('accommodationTypePriceHistoryModal', static_1.downgradeComponent({ component: accommodation_type_price_history_modal_component_1.AccommodationTypePriceHistoryModalComponent }))
    .factory('organisationService', static_1.downgradeInjectable(organisation_service_1.OrganisationService));
class OrganisationModule {
}
exports.OrganisationModule = OrganisationModule;
OrganisationModule.ɵmod = i0.ɵɵdefineNgModule({ type: OrganisationModule });
OrganisationModule.ɵinj = i0.ɵɵdefineInjector({ factory: function OrganisationModule_Factory(t) { return new (t || OrganisationModule)(); }, providers: [
        organisation_service_1.OrganisationService,
        facility_service_1.FacilityService,
        { provide: dialog_1.MAT_DIALOG_DATA, useValue: {} },
        { provide: dialog_1.MatDialogRef, useValue: {} }
    ], imports: [[
            shared_module_1.SharedModule,
            platform_browser_1.BrowserModule,
            devextreme_angular_1.DxValidatorModule,
            devextreme_angular_1.DxDateBoxModule,
            tooltip_1.MatTooltipModule,
            dialog_2.MatDialogModule,
            icon_1.MatIconModule,
            common_1.CommonModule,
            forms_1.FormsModule,
            forms_1.ReactiveFormsModule,
            dnaR_datePicker_module_1.DnaRDatePickerModule,
            dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
            dnaR_email_input_module_1.DnaREmailInputModule,
            dnaR_input_module_1.DnaRInputModule,
            tabs_1.MatTabsModule,
            chips_1.MatChipsModule,
            form_field_1.MatFormFieldModule,
            button_1.MatButtonModule,
            shared_module_1.SharedModule,
            overlay_1.OverlayModule,
            angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: organisation_states_1.states })
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(OrganisationModule, { declarations: [accommodation_type_price_history_modal_component_1.AccommodationTypePriceHistoryModalComponent,
        edit_bed_status_component_1.EditBedStatusComponent,
        facility_details_tab_group_component_1.FacilityDetailsTabGroupComponent,
        facility_details_tab_component_1.FacilityDetailsTabComponent,
        facility_details_view_tab_component_1.FacilityDetailsViewTabComponent,
        facility_details_edit_tab_component_1.FacilityDetailsEditTabComponent,
        value_with_effective_date_component_1.ValueWithEffectiveDateDialogComponent,
        lead_tags_tab_component_1.LeadTagsTabComponent,
        lead_tags_view_tab_component_1.LeadTagsViewTabComponent,
        lead_tags_edit_tab_component_1.LeadTagsEditTabComponent], imports: [shared_module_1.SharedModule,
        platform_browser_1.BrowserModule,
        devextreme_angular_1.DxValidatorModule,
        devextreme_angular_1.DxDateBoxModule,
        tooltip_1.MatTooltipModule,
        dialog_2.MatDialogModule,
        icon_1.MatIconModule,
        common_1.CommonModule,
        forms_1.FormsModule,
        forms_1.ReactiveFormsModule,
        dnaR_datePicker_module_1.DnaRDatePickerModule,
        dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
        dnaR_email_input_module_1.DnaREmailInputModule,
        dnaR_input_module_1.DnaRInputModule,
        tabs_1.MatTabsModule,
        chips_1.MatChipsModule,
        form_field_1.MatFormFieldModule,
        button_1.MatButtonModule,
        shared_module_1.SharedModule,
        overlay_1.OverlayModule, i1.UIRouterUpgradeModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(OrganisationModule, [{
        type: core_1.NgModule,
        args: [{
                imports: [
                    shared_module_1.SharedModule,
                    platform_browser_1.BrowserModule,
                    devextreme_angular_1.DxValidatorModule,
                    devextreme_angular_1.DxDateBoxModule,
                    tooltip_1.MatTooltipModule,
                    dialog_2.MatDialogModule,
                    icon_1.MatIconModule,
                    common_1.CommonModule,
                    forms_1.FormsModule,
                    forms_1.ReactiveFormsModule,
                    dnaR_datePicker_module_1.DnaRDatePickerModule,
                    dnaR_dropdown_multiSelect_module_1.DnaRDropdownMultiSelectModule,
                    dnaR_email_input_module_1.DnaREmailInputModule,
                    dnaR_input_module_1.DnaRInputModule,
                    tabs_1.MatTabsModule,
                    chips_1.MatChipsModule,
                    form_field_1.MatFormFieldModule,
                    button_1.MatButtonModule,
                    shared_module_1.SharedModule,
                    overlay_1.OverlayModule,
                    angular_hybrid_1.UIRouterUpgradeModule.forChild({ states: organisation_states_1.states })
                ],
                schemas: [
                    core_1.CUSTOM_ELEMENTS_SCHEMA
                ],
                providers: [
                    organisation_service_1.OrganisationService,
                    facility_service_1.FacilityService,
                    { provide: dialog_1.MAT_DIALOG_DATA, useValue: {} },
                    { provide: dialog_1.MatDialogRef, useValue: {} }
                ],
                declarations: [
                    accommodation_type_price_history_modal_component_1.AccommodationTypePriceHistoryModalComponent,
                    edit_bed_status_component_1.EditBedStatusComponent,
                    facility_details_tab_group_component_1.FacilityDetailsTabGroupComponent,
                    facility_details_tab_component_1.FacilityDetailsTabComponent,
                    facility_details_view_tab_component_1.FacilityDetailsViewTabComponent,
                    facility_details_edit_tab_component_1.FacilityDetailsEditTabComponent,
                    value_with_effective_date_component_1.ValueWithEffectiveDateDialogComponent,
                    lead_tags_tab_component_1.LeadTagsTabComponent,
                    lead_tags_view_tab_component_1.LeadTagsViewTabComponent,
                    lead_tags_edit_tab_component_1.LeadTagsEditTabComponent
                ]
            }]
    }], null, null); })();
